import React from "react"

import SEO from "../components/seo"
import {
  Center,
  // Footer,
  Tag,
  Showcase,
  DisplaySmall,
  DisplayMedium,
} from "../components"
import CartLink from "../components/CartLink"
import { titleIfy, slugify } from "../../utils/helpers"

import { graphql } from "gatsby"
const Home = ({ data: gqlData }) => {
  const {
    inventoryInfo,
    categoryInfo: { data },
  } = gqlData
  const categories = data
  // console.log(categories)
  const inventory = inventoryInfo.data

  return (
    <>
      <CartLink />
      <SEO title="Home" />
      <div className="w-full">
        <div
          className="bg-blue-200
        lg:h-hero
        p-6 pb-2 smpb-6
        flex lg:flex-row flex-col"
        >
          <div className="pt-4 pl-2 sm:pt-12 sm:pl-12 flex flex-col">
            <Tag year={new Date().getFullYear()} category="NEW ARRIVALS" />
            <Center
              price={`${inventory[2].price}.00`}
              title={inventory[2].name}
              link={slugify(inventory[2].name)}
            />
            {/* <Footer designer="Black Label" /> */}
          </div>
          <div className="flex flex-1 justify-center items-center relative">
            <Showcase imageSrc={inventory[2].images[0]} />
            <div
              className="absolute
              w-80 h-80
              bg-white z-0 rounded-full"
            />
          </div>
        </div>
      </div>

      <div className="pt-10 pb-6 flex flex-col items-center">
        <hr className="bg-blue-300 w-88" />
        <h2 className="text-4xl mb-3">The Perfect Fit</h2>
        <p className="text-gray-600 text-sm text-justify max-w-2xl">
          Find <i>the perfect</i> mask to keep you and the <b>little ones </b>
          safe when you leave the house.
          <br />
          <br />
          These Masks are equipped with <b>one-way valve</b> to prevent
          condensation, a <b>pocket</b> for replaceable filters, nose-bridge{" "}
          <b>moulding</b> for a custom fit, and <b>adjustable</b> ear elastic!{" "}
          <br />
          <br />
          <span className="text-xs">
            Each Mask Includes +1 PM2.5 rated filter, which should last you a
            week or two depending on your usage.{" "}
          </span>
        </p>
        <hr className="bg-blue-300 w-88" />
      </div>

      <div className="mb-4 lg:mb-32 flex flex-col lg:flex-row justify-between">
        {/** Pick two random from categories and display this Medium */}
        <DisplayMedium
          imageSrc={categories[2].image}
          subtitle={`${categories[2].itemCount} items`}
          title={titleIfy(categories[2].name)}
          link={slugify(categories[2].name)}
        />
        <DisplayMedium
          imageSrc={categories[1].image}
          subtitle={`${categories[1].itemCount} items`}
          title={titleIfy(categories[1].name)}
          link={slugify(categories[1].name)}
        />
      </div>

      <div className="my-8 flex flex-col lg:flex-row justify-between">
        <DisplaySmall
          imageSrc={inventory[1].images[0]}
          title={inventory[1].name}
          subtitle={inventory[1].categories[1]}
          link={slugify(inventory[1].name)}
        />

        <DisplaySmall
          imageSrc={inventory[6].images[0]}
          title={inventory[6].name}
          subtitle={inventory[6].categories[1]}
          link={slugify(inventory[6].name)}
        />

        <DisplaySmall
          imageSrc={inventory[11].images[0]}
          title={inventory[11].name}
          subtitle={inventory[11].categories[0]}
          link={slugify(inventory[11].name)}
        />

        <DisplaySmall
          imageSrc={inventory[12].images[0]}
          title={inventory[12].name}
          subtitle={inventory[12].categories[0]}
          link={slugify(inventory[12].name)}
        />
      </div>
    </>
  )
}
export function random_item(items) {
  return items[Math.floor(Math.random() * items.length)]
}

export const pageQuery = graphql`
  query {
    navInfo {
      data
    }
    categoryInfo {
      data {
        name
        image
        itemCount
      }
    }
    inventoryInfo {
      data {
        images
        price
        name
        categories
        description
        sku
      }
    }
  }
`

export default Home
