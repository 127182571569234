import React from "react"
import { Link } from "gatsby"
import Image from "../Image"
import { random_item } from "../../pages/index"

const randColors = ["teal", "light", "purple", "indigo", "pink"]

const DisplayMedium = ({ imageSrc, title, subtitle, link }) => {
  return (
    <div
      className={`lg:w-flex-half mb-4 lg:mb-0 bg-light p-8 pb-0 hover:bg-${random_item(randColors)}-200`}
    >
      <Link to={`/${link}`}>
        <div className="flex flex-column justify-center items-center h-56">
          <Image
            src={imageSrc}
            alt={title}
            className="h-60 w-60 rounded-full object-cover"
          />
        </div>
        <div className="">
          <p className="text-3xl font-semibold mb-1">{title}</p>
          <p className="text-sm text-gray-700">{subtitle}</p>
        </div>
      </Link>
    </div>
  )
}

export default DisplayMedium
